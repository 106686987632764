
import type { PropType } from 'vue'
import { defineComponent, ref, nextTick } from 'vue'
import { formatDate } from '@/services/dateTimeService'
import TmButtonMore from '@/components/shared/TmButtonMore.vue'
import type { MessageStatuses, ReplyType } from '@/definitions/shared/chat/types'
import type { PersonType } from '@/definitions/shared/types'
import ChatLogTemplateDropdown from '@/components/shared/chat/chatLog/ChatLogTemplateDropdown.vue'
import ChatMessageInfo from '@/components/shared/chat/ChatMessageInfo.vue'
import { useBreakpoints } from '@/compositions/breakpoints'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import ChatLogTemplateUserName from '@/components/shared/chat/ChatLogTemplateUserName.vue'
// import ChatLogTemplateUserName from '@/components/shared/chat/ChatLogTemplateUserName.vue'

export default defineComponent({
  components: {
    ChatLogTemplateUserName,
    // ChatLogTemplateUserName,
    ChatLogTemplateDropdown,
    TmButtonMore,
    ChatMessageInfo,
    TmDropdown,
    TmDropdownItem,
  },
  props: {
    outcoming: {
      type: Boolean,
    },
    date: {
      type: String,
    },
    selected: {
      type: Boolean,
    },
    showSelection: {
      type: Boolean,
    },
    messageStatus: {
      type: String as PropType<MessageStatuses>,
    },
    isEdited: {
      type: Boolean,
    },
    widget: {
      type: Boolean,
    },
    reply: {
      type: Object as PropType<ReplyType>,
    },
    contact: {
      type: Object as PropType<PersonType>,
    },
    serviceSender: {
      type: Boolean,
    },
    noteState: {
      type: Boolean,
    },
    colorful: {
      type: Boolean,
    },
  },
  emits: ['update:selected', 'setScrollbar'],
  setup(props, context) {
    const dropdownState = ref<boolean>(false)
    const messageRef = ref()
    const mobileActionsDropdown = ref()
    const { isMdMax } = useBreakpoints()
    const handleTouch = () => {
      if (isMdMax.value) {
        dropdownState.value = !dropdownState.value

        nextTick(() => {
          context.emit('setScrollbar', messageRef.value)
        })
      }
    }
    const handleClick = () => {
      if (props.showSelection) {
        context.emit('update:selected', !props.selected)
      }
    }
    const outcomingMessageContact = ref(props.contact?.fullName ? props.contact.fullName : 'John Doe')
    const showAuthor = ref(props.widget && !props.outcoming && !props.serviceSender)

    return {
      handleClick,
      messageRef,
      isMdMax,
      handleTouch,
      formatDate,
      mobileActionsDropdown,
      dropdownState,
      outcomingMessageContact,
      showAuthor,
    }
  },
})
