import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2d9fb48e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "chats-content-background__pattern"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "chats-content-background",
    style: _normalizeStyle({
      background: _ctx.customColor,
    })
  }, [
    (_ctx.showPattern)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : _createCommentVNode("", true)
  ], 4))
}