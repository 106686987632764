
import type { PropType } from 'vue'
import { defineComponent, computed } from 'vue'
import type { ChatCallTypes } from '@/definitions/shared/chat/types'

type CallInfoType = {
  icon: string;
  iconClass: string;
  text: string;
}

export default defineComponent({
  props: {
    callType: {
      type: String as PropType<ChatCallTypes>,
    },
    outcoming: {
      type: Boolean,
    },
  },
  setup(props) {
    const callTypeInfo = computed((): CallInfoType => {
      if (props.callType === 'missed') {
        return {
          icon: 'phone_missed',
          iconClass: 'error--text',
          text: 'Missed call',
        }
      }

      return {
        icon: props.outcoming ? 'tmi-phone-forwarded' : 'phone_callback',
        iconClass: props.outcoming ? 'primary--text' : 'success--text',
        text: props.outcoming ? 'Outbound call' : 'Inbound call',
      }
    })

    return {
      callTypeInfo,
    }
  },
})
