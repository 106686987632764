import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-15acd5da"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex align-center" }
const _hoisted_2 = { class: "body-text-regular-14 lh-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tm_icon, {
      name: _ctx.callTypeInfo.icon,
      class: _normalizeClass(["mr-6px selected--white-text", _ctx.callTypeInfo.iconClass]),
      size: "xLarge"
    }, null, 8, ["name", "class"]),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.callTypeInfo.text), 1)
  ]))
}