
import { defineComponent } from 'vue'
import { formatDate } from '@/services/dateTimeService'
import TmStylizedText from '@/components/shared/TmStylizedText.vue'
import type { TextArray } from '@/definitions/shared/types'
import type { PropType } from 'vue'

export default defineComponent({
  components: {
    TmStylizedText,
  },
  props: {
    text: {
      type: [String, Array] as PropType<string | TextArray[]>,
    },
    date: {
      type: String,
    },
    widget: {
      type: Boolean,
    },
  },
  setup() {
    return {
      formatDate,
    }
  },
})
