import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_dropdown_item = _resolveComponent("tm-dropdown-item")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_tm_dropdown_item, {
      icon: "check_box",
      label: "Select",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:selected')))
    }),
    _createVNode(_component_tm_dropdown_item, {
      icon: "tmi-layers-tasks",
      label: "Create task",
      onClick: _ctx.createTask
    }, null, 8, ["onClick"]),
    _createVNode(_component_tm_dropdown_item, {
      icon: "info",
      label: "Message details",
      onClick: _ctx.openDetailsModal
    }, null, 8, ["onClick"]),
    _createVNode(_component_tm_dropdown_item, {
      icon: "tmi-duplicate",
      label: "Copy"
    }),
    _createVNode(_component_tm_dropdown_item, {
      icon: "link",
      label: "Share direct link"
    }),
    _createVNode(_component_tm_dropdown_item, {
      icon: "get_app",
      label: "Download PDF"
    }),
    (_ctx.outcoming)
      ? (_openBlock(), _createBlock(_component_tm_dropdown_item, {
          key: 0,
          icon: "delete",
          label: "Delete"
        }))
      : _createCommentVNode("", true)
  ]))
}