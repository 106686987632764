
import type { PropType } from 'vue'
import { defineComponent, computed } from 'vue'
import ChatMessageAttachments from '@/components/shared/chat/ChatMessageAttachments.vue'
import ChatLogTemplate from '@/components/shared/chat/ChatLogTemplate.vue'
import type { MessageStatuses, ChatAttachmentType } from '@/definitions/shared/chat/types'
import type { TextArray } from '@/definitions/shared/types'
import { imageExtensions } from '@/definitions/shared/types'
import ChatMessageInfoSpacer from '@/components/shared/chat/ChatMessageInfoSpacer.vue'
import Highlight from '@/components/shared/Highlight.vue'
import TmStylizedText from '@/components/shared/TmStylizedText.vue'

export default defineComponent({
  components: {
    TmStylizedText,
    Highlight,
    ChatMessageInfoSpacer,
    ChatLogTemplate,
    ChatMessageAttachments,
  },
  props: {
    attachments: {
      type: Array as PropType<ChatAttachmentType[]>,
      default: () => [],
    },
    text: {
      type: [String, Array] as PropType<string | TextArray[]>,
      default: '',
    },
    search: {
      type: String,
      default: '',
    },
    messageStatus: {
      type: String as PropType<MessageStatuses>,
    },
    outcoming: {
      type: Boolean,
    },
    date: {
      type: String,
    },
  },
  setup(props) {
    const isImageAttachment = computed(() => props.attachments.length === 1 && imageExtensions.includes(props.attachments[0].extension))

    return {
      isImageAttachment,
    }
  },
})
