
import { defineComponent } from 'vue'
import { formatDate } from '@/services/dateTimeService'

export default defineComponent({
  props: {
    date: {
      type: String,
    },
  },
  setup() {
    return {
      formatDate,
    }
  },
})
