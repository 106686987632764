
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    showPattern: {
      default: true,
      type: Boolean,
    },
    customColor: {
      type: String,
    },
  },
})
