
import type { PropType } from 'vue'
import { defineComponent, computed } from 'vue'
import ChatMessage from '@/components/shared/chat/ChatMessage.vue'
import ChatAction from '@/components/shared/chat/ChatAction.vue'
import ChatDate from '@/components/shared/chat/ChatDate.vue'
import ChatCall from '@/components/shared/chat/ChatCall.vue'
import ChatPreForm from '@/components/shared/chat/ChatPreForm.vue'
import ChatNewDivider from '@/components/shared/chat/ChatNewDivider.vue'
import ChatNote from '@/components/shared/chat/ChatNote.vue'
import ChatRate from '@/components/shared/chat/ChatRate.vue'
import ChatFeedback from '@/components/shared/chat/ChatFeedback.vue'
import type { ChatLogItemTypes } from '@/definitions/shared/chat/types'

export default defineComponent({
  components: {
    ChatNote,
    ChatMessage,
    ChatPreForm,
    ChatAction,
    ChatNewDivider,
    ChatDate,
    ChatCall,
    ChatRate,
    ChatFeedback,
  },
  props: {
    type: {
      type: String as PropType<ChatLogItemTypes>,
      required: true,
    },
  },
  setup(props) {
    const chatLogComponent = computed(() => {
      switch (props.type) {
        case 'preChatForm': return 'chat-pre-form'
        case 'message': return 'chat-message'
        case 'action': return 'chat-action'
        case 'date': return 'chat-date'
        case 'call': return 'chat-call'
        case 'note': return 'chat-note'
        case 'newDivider': return 'chat-new-divider'
        case 'rate': return 'chat-rate'
        case 'feedback': return 'chat-feedback'
        default: return 'div'
      }
    })

    return {
      chatLogComponent,
    }
  },
})
