
import { defineComponent, computed, ref } from 'vue'
import type { ChatAttachmentType } from '@/definitions/shared/chat/types'
import type { PropType } from 'vue'
import ChatMessageAttachmentsList from '@/components/shared/chat/attachments/ChatMessageAttachmentsList.vue'
import ChatMessageAttachmentImage from '@/components/shared/chat/attachments/ChatMessageAttachmentImage.vue'

export default defineComponent({
  components: {
    ChatMessageAttachmentsList,
    ChatMessageAttachmentImage,
  },
  props: {
    attachments: {
      type: Array as PropType<ChatAttachmentType[]>,
      required: true,
    },
    isImageAttachment: {
      type: Boolean,
    },
  },
  setup(props) {
    const image = ref(props.attachments[0])
    const componentName = computed(() => props.isImageAttachment ? 'chat-message-attachment-image' : 'chat-message-attachments-list')

    return {
      image,
      componentName,
    }
  },
})
