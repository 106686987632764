
import { defineComponent, ref } from 'vue'
import { formatDate } from '@/services/dateTimeService'
import { cropText } from '@/services/utils'
import Highlight from '@/components/shared/Highlight.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmButtonMore from '@/components/shared/TmButtonMore.vue'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  components: {
    TmButton,
    TmDropdown,
    TmDropdownItem,
    Highlight,
    TmButtonMore,
  },
  props: {
    date: {
      type: String,
    },
    text: {
      type: String,
      default: '',
    },
    author: {
      type: String,
    },
    search: {
      type: String,
    },
  },
  setup(props) {
    const isExpanded = ref(false)
    const isLongText = ref(props.text.length > 80)
    const internalText = ref(cropText(props.text))
    const dropdownValue = ref(false)
    const { openModal } = useModals()

    const openDeleteNoteModal = () => {
      openModal('confirmation', {
        title: 'Delete note',
        text: 'Are you sure you would like to delete this internal note?\nThis actions is permament and cannot be undone.',
        btnColor: 'error',
        btnText: 'Delete',
      })
    }

    return {
      openDeleteNoteModal,
      dropdownValue,
      openModal,
      formatDate,
      isExpanded,
      internalText,
      isLongText,
    }
  },
})
