
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import ChatMessageAttachment from '@/components/shared/chat/attachments/ChatMessageAttachment.vue'
import type { ChatAttachmentType } from '@/definitions/shared/chat/types'

export default defineComponent({
  components: {
    ChatMessageAttachment,
  },
  props: {
    attachments: {
      type: Array as PropType<ChatAttachmentType[]>,
    },
  },
})
