import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c8e364e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chat-message-info" }
const _hoisted_2 = { class: "lh-16 body-text-regular-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chat_delivery_status = _resolveComponent("chat-delivery-status")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.formatDate(_ctx.date, 'h:mm a')), 1),
    (_ctx.messageStatus && _ctx.outcoming && !_ctx.noteState)
      ? (_openBlock(), _createBlock(_component_chat_delivery_status, {
          key: 0,
          "message-status": _ctx.messageStatus,
          class: "ml-1"
        }, null, 8, ["message-status"]))
      : _createCommentVNode("", true)
  ]))
}