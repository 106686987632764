import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_stylized_text = _resolveComponent("tm-stylized-text")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["chat-feedback", {
      [`chat-feedback--${_ctx.rateStatus}`]: _ctx.rateStatus
    }])
  }, [
    _createVNode(_component_tm_stylized_text, { text: _ctx.text }, null, 8, ["text"])
  ], 2))
}