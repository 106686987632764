
import { defineComponent } from 'vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import { useModals } from '@/compositions/modals'
import { messageDetailsSent, messageDetailsReceived } from '@/definitions/chats/messages/data'

export default defineComponent({
  components: {
    TmDropdownItem,
  },
  props: {
    selected: {
      type: Boolean,
    },
    outcoming: {
      type: Boolean,
    },
  },
  emits: ['update:selected', 'update:dropdownState'],
  setup(props) {
    const { openModal } = useModals()

    const openDetailsModal = () => {
      if (props.outcoming) {
        openModal('chatsMessageDetails', {
          modalTitle: 'Sent message details',
          items: messageDetailsSent,
        })
      } else {
        openModal('chatsMessageDetails', {
          modalTitle: 'Received message details',
          items: messageDetailsReceived,
        })
      }
    }
    const createTask = () => {
      openModal('tasks', {
        description: 'Related to the chat 332211 with Cody Fisher.\nRelated message:\n”You can reach me at my email address, smsuser123@example.com.”',
      })
    }

    return {
      openDetailsModal,
      createTask,
      openModal,
    }
  },
})
