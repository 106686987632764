import { resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6c4890b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-inline visibility-hidden ml-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chat_message_info = _resolveComponent("chat-message-info")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_chat_message_info, _normalizeProps(_guardReactiveProps(_ctx.$attrs)), null, 16)
  ]))
}