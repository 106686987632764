import type { ChatLogItemType } from '@/definitions/shared/chat/types'
import type { CustomColorPaletteType } from '@/definitions/_general/_types/colorPalette'

export const generalThemeMessagesItems: ChatLogItemType[] = [
  {
    type: 'message',
    date: '2022-04-10T00:14:26.429Z',
    text: 'Wow! Nice theme!',
  },
  {
    type: 'message',
    date: '2022-04-10T00:14:27.429Z',
    text: 'Hi guys!',
  },
  {
    type: 'message',
    date: '2022-04-10T00:14:28.429Z',
    outcoming: true,
    text: 'This is my favorite color!',
  },
]

export const generalThemeColorPalette: CustomColorPaletteType[] = [
  {
    customColor: 'linear-gradient(0deg, rgba(0, 0, 0, .08), rgba(0, 0, 0, .08)), linear-gradient(230.94deg, rgba(159, 99, 235, .4) 0%, rgba(46, 161, 255, .4) 100%), linear-gradient(0deg, white, white), white',
  },
  {
    customColor: 'linear-gradient(0deg, rgba(0, 0, 0, .08), rgba(0, 0, 0, .08)), linear-gradient(0deg, #A5CFF1, #A5CFF1), #FFFFFF',
  },
  {
    customColor: 'linear-gradient(90deg, #CCF2F5 20.78%, #9FB4FC 100%)',
  },
  {
    customColor: 'linear-gradient(90deg, #BFEBAA 0%, #7C95EA 100%)',
  },
  {
    customColor: 'linear-gradient(90deg, #D2DE8F 0%, #93D8AF 100%)',
  },
  {
    customColor: 'linear-gradient(270deg, #FCCFB7 0%, #F5F193 68.08%)',
  },
  {
    customColor: 'linear-gradient(90deg, #FFDDB5 0%, #C5BCFF 100%)',
  },
  {
    customColor: 'linear-gradient(0deg, rgba(0, 0, 0, .08), rgba(0, 0, 0, .08)), #FFFFFF',
  },
]
