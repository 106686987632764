
import { defineComponent } from 'vue'
import TmStylizedText from '@/components/shared/TmStylizedText.vue'
import type { TextArray } from '@/definitions/shared/types'
import type { PropType } from 'vue'
import useWidget from '@/compositions/chatWidget'

export default defineComponent({
  components: {
    TmStylizedText,
  },
  props: {
    text: {
      type: [String, Array] as PropType<string | TextArray[]>,
    },
  },
  setup() {
    const { rateStatus } = useWidget()
    return {
      rateStatus,
    }
  },
})
