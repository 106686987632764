import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, renderSlot as _renderSlot, withModifiers as _withModifiers, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-66cb903a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "chat-log-template__reply"
}
const _hoisted_2 = { class: "body-text-semi-bold-14 lh-20 distinct--text" }
const _hoisted_3 = { class: "lh-20 distinct--text" }
const _hoisted_4 = {
  key: 1,
  class: "chat-log-template__author"
}
const _hoisted_5 = { class: "d-flex align-center flex-grow-1" }
const _hoisted_6 = { class: "font-weight-semi-bold selected--white-text emphasize--text" }
const _hoisted_7 = {
  key: 0,
  class: "body-text-regular-12 distinct--text lh-16 flex-grow-0 ml-2"
}
const _hoisted_8 = { class: "d-flex align-end" }
const _hoisted_9 = { class: "overflow-hidden" }
const _hoisted_10 = { class: "hide-on-hover chat-log-template__date" }
const _hoisted_11 = {
  key: 0,
  class: "chat-log-template__mobile-actions"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_field = _resolveComponent("tm-field")!
  const _component_chat_log_template_user_name = _resolveComponent("chat-log-template-user-name")!
  const _component_tm_button_more = _resolveComponent("tm-button-more")!
  const _component_tm_dropdown_item = _resolveComponent("tm-dropdown-item")!
  const _component_chat_log_template_dropdown = _resolveComponent("chat-log-template-dropdown")!
  const _component_tm_dropdown = _resolveComponent("tm-dropdown")!
  const _component_chat_message_info = _resolveComponent("chat-message-info")!
  const _directive_touch_hold = _resolveDirective("touch-hold")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["chat-log-template", {
      'chat-log-template--outcoming': _ctx.outcoming,
      'chat-log-template--note': _ctx.noteState,
      'chat-log-template--selected': _ctx.selected || (_ctx.dropdownState && _ctx.isMdMax),
      'chat-log-template--selection': _ctx.showSelection,
    }]),
    onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
  }, [
    (_ctx.showSelection)
      ? (_openBlock(), _createBlock(_component_tm_field, {
          key: 0,
          "model-value": _ctx.selected,
          type: "checkbox",
          class: "mr-4",
          "onUpdate:modelValue": _ctx.handleClick
        }, null, 8, ["model-value", "onUpdate:modelValue"]))
      : _createCommentVNode("", true),
    _withDirectives((_openBlock(), _createElementBlock("div", {
      ref: "messageRef",
      class: _normalizeClass(["chat-log-template__wrapper", {
        'ml-auto': _ctx.outcoming || _ctx.noteState,
      }])
    }, [
      _withDirectives((_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["chat-log-template__body", {
          'chat-log-template__body--dropdown-active': _ctx.dropdownState,
          'chat-log-template__body--show-selection': _ctx.showSelection,
          'chat-log-template__body--colorful': _ctx.colorful,
        }]),
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('setScrollbar', _ctx.messageRef)))
      }, [
        (_ctx.widget)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (_ctx.reply)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.reply.name), 1),
                    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.reply.text), 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.showAuthor)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.outcomingMessageContact), 1)
                    ]),
                    (_ctx.isEdited)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_7, "Edited"))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ], 64))
          : (_ctx.outcoming || _ctx.noteState)
            ? (_openBlock(), _createBlock(_component_chat_log_template_user_name, {
                key: 1,
                "note-state": _ctx.noteState
              }, null, 8, ["note-state"]))
            : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ]),
          (!_ctx.isMdMax)
            ? (_openBlock(), _createBlock(_component_tm_dropdown, {
                key: 0,
                modelValue: _ctx.dropdownState,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dropdownState) = $event)),
                "no-parent-event": "",
                class: "show-on-hover chat-log-template__actions"
              }, {
                menu: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "menu", {}, () => [
                    (_ctx.widget)
                      ? (_openBlock(), _createBlock(_component_tm_dropdown_item, {
                          key: 0,
                          icon: "tmi-duplicate",
                          label: "Copy"
                        }))
                      : (_openBlock(), _createBlock(_component_chat_log_template_dropdown, {
                          key: 1,
                          outcoming: _ctx.outcoming,
                          selected: _ctx.selected,
                          "onUpdate:selected": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:selected', !_ctx.selected)))
                        }, null, 8, ["outcoming", "selected"]))
                  ], true)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_tm_button_more, {
                    "btn-size": "medium",
                    "icon-size": "xLarge",
                    "icon-class": _ctx.selected ? 'white--text' : 'distinct--text',
                    flat: "",
                    color: "transparent",
                    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.dropdownState = !_ctx.dropdownState), ["stop"]))
                  }, null, 8, ["icon-class"])
                ]),
                _: 3
              }, 8, ["modelValue"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_component_chat_message_info, {
            "note-state": _ctx.noteState,
            date: _ctx.date,
            outcoming: _ctx.outcoming,
            "message-status": _ctx.messageStatus
          }, null, 8, ["note-state", "date", "outcoming", "message-status"])
        ])
      ], 2)), [
        [
          _directive_touch_hold,
          _ctx.handleTouch,
          "1000",
          { mouse: true }
        ]
      ]),
      (_ctx.isMdMax && !_ctx.showSelection)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _renderSlot(_ctx.$slots, "menu", {}, () => [
              (_ctx.widget)
                ? (_openBlock(), _createBlock(_component_tm_dropdown_item, {
                    key: 0,
                    icon: "tmi-duplicate",
                    label: "Copy"
                  }))
                : (_openBlock(), _createBlock(_component_chat_log_template_dropdown, {
                    key: 1,
                    ref: "mobileActionsDropdown",
                    outcoming: _ctx.outcoming,
                    selected: _ctx.selected,
                    "onUpdate:selected": _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('update:selected', !_ctx.selected)))
                  }, null, 8, ["outcoming", "selected"]))
            ], true)
          ]))
        : _createCommentVNode("", true)
    ], 2)), [
      [_directive_click_outside, () => _ctx.dropdownState = false]
    ])
  ], 2))
}