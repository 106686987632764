import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-end" }
const _hoisted_2 = {
  key: 0,
  class: "mt-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_highlight = _resolveComponent("highlight")!
  const _component_tm_stylized_text = _resolveComponent("tm-stylized-text")!
  const _component_chat_message_info_spacer = _resolveComponent("chat-message-info-spacer")!
  const _component_chat_message_attachments = _resolveComponent("chat-message-attachments")!
  const _component_chat_log_template = _resolveComponent("chat-log-template")!

  return (_openBlock(), _createBlock(_component_chat_log_template, {
    date: _ctx.date,
    outcoming: _ctx.outcoming,
    "message-status": _ctx.messageStatus
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default", {}, () => [
          (typeof _ctx.text === 'string')
            ? (_openBlock(), _createBlock(_component_highlight, {
                key: 0,
                text: _ctx.text,
                search: _ctx.search
              }, null, 8, ["text", "search"]))
            : (_openBlock(), _createBlock(_component_tm_stylized_text, {
                key: 1,
                text: _ctx.text
              }, null, 8, ["text"]))
        ]),
        (!_ctx.attachments.length)
          ? (_openBlock(), _createBlock(_component_chat_message_info_spacer, {
              key: 0,
              "message-status": _ctx.messageStatus,
              outcoming: _ctx.outcoming,
              date: _ctx.date
            }, null, 8, ["message-status", "outcoming", "date"]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.attachments.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_chat_message_attachments, {
              attachments: _ctx.attachments,
              "is-image-attachment": _ctx.isImageAttachment
            }, {
              append: _withCtx(() => [
                _createVNode(_component_chat_message_info_spacer, {
                  "message-status": _ctx.messageStatus,
                  outcoming: _ctx.outcoming,
                  date: _ctx.date
                }, null, 8, ["message-status", "outcoming", "date"])
              ]),
              _: 1
            }, 8, ["attachments", "is-image-attachment"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["date", "outcoming", "message-status"]))
}