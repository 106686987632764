
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { TmFileExtension } from '@/definitions/shared/types'
import TmEllipsis from '@/components/shared/TmEllipsis.vue'

export default defineComponent({
  components: {
    TmEllipsis,
  },
  props: {
    url: {
      type: String,
    },
    size: {
      type: String,
    },
    extension: {
      type: String as PropType<TmFileExtension>,
    },
    title: {
      type: String,
    },
  },
})
