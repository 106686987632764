
import { defineComponent, ref } from 'vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import ChatsContentBackground from '@/components/pages/chats/content/ChatsContentBackground.vue'
import ChatLogItem from '@/components/shared/chat/ChatLogItem.vue'
import { generalThemeMessagesItems, generalThemeColorPalette } from '@/definitions/chats/settings/general/data'
import TmColorPalette from '@/components/shared/TmColorPalette.vue'

export default defineComponent({
  components: {
    TmColorPalette,
    ChatLogItem,
    ChatsContentBackground,
    TmFormLine,
    InfoCard,
  },
  setup() {
    const showPattern = ref(true)
    const items = generalThemeMessagesItems
    const color = ref(generalThemeColorPalette[0])

    return {
      color,
      generalThemeColorPalette,
      showPattern,
      items,
    }
  },
})
