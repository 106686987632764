
import { defineComponent } from 'vue'
import useWidget from '@/compositions/chatWidget'

export default defineComponent({
  setup() {
    const { rateStatus } = useWidget()
    return {
      rateStatus,
    }
  },
})
