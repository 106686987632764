import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chat_message_attachment = _resolveComponent("chat-message-attachment")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.attachments, (attachment, i) => {
      return (_openBlock(), _createBlock(_component_chat_message_attachment, _mergeProps({ key: i }, attachment), _createSlots({ _: 2 }, [
        (i + 1 === _ctx.attachments.length)
          ? {
              name: "append",
              fn: _withCtx(() => [
                _renderSlot(_ctx.$slots, "append")
              ])
            }
          : undefined
      ]), 1040))
    }), 128))
  ]))
}