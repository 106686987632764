
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import ChatMessage from '@/components/shared/chat/ChatMessage.vue'
import Highlight from '@/components/shared/Highlight.vue'
import type { ChatSenderType } from '@/definitions/shared/chat/types'

export default defineComponent({
  components: {
    ChatMessage,
    Highlight,
  },
  props: {
    text: {
      type: String,
    },
    search: {
      type: String,
      default: '',
    },
    sender: {
      type: Object as PropType<ChatSenderType>,
      required: true,
    },
    widget: {
      type: Boolean,
    },
  },
})
