import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4f137f53"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chat-action" }
const _hoisted_2 = { class: "flex-shrink-0 ml-1 font-weight-normal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_stylized_text = _resolveComponent("tm-stylized-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tm_stylized_text, {
      text: _ctx.text,
      truncate: !_ctx.widget
    }, null, 8, ["text", "truncate"]),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.formatDate(_ctx.date, 'h:mm a')), 1)
  ]))
}