
import { defineComponent, ref } from 'vue'
import ChatsSettingsPage from '@/components/pages/chats/settings/ChatsSettingsPage.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import InfoCards from '@/components/shared/templates/InfoCards.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmButton from '@/components/shared/TmButton.vue'
import { useModals } from '@/compositions/modals'
import { editorBtns } from '@/definitions/shared/editor/data'
import ChatsSettingsGeneralTheme from '@/components/pages/chats/settings/general/ChatsSettingsGeneralTheme.vue'

export default defineComponent({
  components: {
    ChatsSettingsGeneralTheme,
    InfoCards,
    TmFormLine,
    TmButton,
    DetailsHero,
    InfoCard,
    ChatsSettingsPage,
  },
  setup() {
    const { openModal } = useModals()
    const breadcrumbs = [
      { label: 'Messenger', url: { name: 'base.chats' } },
      { label: 'Settings', url: { name: 'base.chats.settings' } },
      { label: 'General' },
    ]
    const showCustomSound = ref(false)
    const shortcuts = ref('enter')
    const desktopNotifications = ref(true)
    const messageNotifications = ref(true)
    const soundNotifications = ref('default')
    const signature = ref('All the best,\n{User.name}\n{User.role}\n{User.email}\n{Workspace.phone.number}\n{Workspace.address}')
    const autoSolveUnsubscribe = ref(true)
    const autoSolveAfter = ref(true)
    const solveAfterNumber = ref(5)
    const solveAfterTime = ref('Hours')
    const solveAfterTimeOptions = ref([
      'Hours', 'Days', 'Weeks', 'Months', 'Years',
    ])
    const handleCustomSoundClick = () => {
      showCustomSound.value = true
      openModal('composeAudioRecordings', { modalTitle: 'Your custom sound' })
    }

    const btnsList = [
      editorBtns.text,
      editorBtns.emoji,
      editorBtns.tags,
      editorBtns.code,
    ]

    return {
      openModal,
      handleCustomSoundClick,
      showCustomSound,
      solveAfterNumber,
      solveAfterTimeOptions,
      solveAfterTime,
      autoSolveUnsubscribe,
      autoSolveAfter,
      signature,
      soundNotifications,
      desktopNotifications,
      messageNotifications,
      shortcuts,
      breadcrumbs,
      btnsList,
    }
  },
})
