import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_spinner = _resolveComponent("tm-spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.statusInfo.loading)
      ? (_openBlock(), _createBlock(_component_tm_icon, {
          key: 0,
          name: _ctx.statusInfo.icon,
          class: _normalizeClass(_ctx.statusInfo.iconClass)
        }, null, 8, ["name", "class"]))
      : (_openBlock(), _createBlock(_component_tm_spinner, {
          key: 1,
          size: "16px",
          class: "light--text"
        }))
  ]))
}