
import { defineComponent } from 'vue'
import { formatDate } from '@/services/dateTimeService'
import type { MessageStatuses } from '@/definitions/shared/chat/types'
import type { PropType } from 'vue'
import ChatDeliveryStatus from '@/components/shared/chat/ChatDeliveryStatus.vue'

export default defineComponent({
  components: {
    ChatDeliveryStatus,
  },
  props: {
    messageStatus: {
      type: String as PropType<MessageStatuses>,
    },
    outcoming: {
      type: Boolean,
    },
    date: {
      type: String,
    },
    noteState: {
      type: Boolean,
    },
  },
  setup() {
    return {
      formatDate,
    }
  },
})
