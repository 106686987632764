
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import ChatLogTemplate from '@/components/shared/chat/ChatLogTemplate.vue'
import ChatCallType from '@/components/shared/chat/call/ChatCallType.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import { useModals } from '@/compositions/modals'
import { messageDetailsCall } from '@/definitions/chats/messages/data'
import type { MessageStatuses, ChatCallTypes } from '@/definitions/shared/chat/types'
import ChatMessageInfoSpacer from '@/components/shared/chat/ChatMessageInfoSpacer.vue'

export default defineComponent({
  components: {
    ChatMessageInfoSpacer,
    TmDropdownItem,
    ChatCallType,
    ChatLogTemplate,
  },
  props: {
    selected: {
      type: Boolean,
    },
    messageStatus: {
      type: String as PropType<MessageStatuses>,
    },
    outcoming: {
      type: Boolean,
    },
    date: {
      type: String,
    },
    duration: {
      type: String,
    },
    callType: {
      type: String as PropType<ChatCallTypes>,
    },
  },
  emits: ['update:selected'],
  setup(props) {
    const { openModal } = useModals()
    const openDetailsModal = () => {
      openModal('chatsMessageDetails', {
        modalTitle: 'Call details',
        items: messageDetailsCall,
      })
    }

    return {
      openDetailsModal,
    }
  },
})
