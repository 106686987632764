
import type { PropType } from 'vue'
import TmSpinner from '@/components/shared/TmSpinner.vue'
import { defineComponent, computed } from 'vue'
import type { MessageStatuses } from '@/definitions/shared/chat/types'

type MessageStatusType = {
  iconClass?: string;
  icon?: string;
  loading?: boolean;
}

export default defineComponent({
  components: {
    TmSpinner,
  },
  props: {
    messageStatus: {
      type: String as PropType<MessageStatuses>,
    },
  },
  setup(props) {
    const statusInfo = computed((): MessageStatusType => {
      switch (props.messageStatus) {
        case 'loading': return { loading: true }
        case 'wait': return { iconClass: 'light--text', icon: 'schedule' }
        case 'sent': return { iconClass: 'light--text', icon: 'check' }
        case 'delivered': return { iconClass: 'success--text', icon: 'check' }
        case 'read': return { iconClass: 'success--text', icon: 'done_all' }
        case 'rejected': return { iconClass: 'warning--text', icon: 'warning' }
        case 'failed': return { iconClass: 'error--text', icon: 'warning' }
        default: return {}
      }
    })

    return {
      statusInfo,
    }
  },
})
