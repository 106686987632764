import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-038cbf9c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex align-center" }
const _hoisted_2 = {
  key: 0,
  class: "chat-call__duration selected--white-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chat_call_type = _resolveComponent("chat-call-type")!
  const _component_chat_message_info_spacer = _resolveComponent("chat-message-info-spacer")!
  const _component_tm_dropdown_item = _resolveComponent("tm-dropdown-item")!
  const _component_chat_log_template = _resolveComponent("chat-log-template")!

  return (_openBlock(), _createBlock(_component_chat_log_template, {
    selected: _ctx.selected,
    outcoming: _ctx.outcoming,
    date: _ctx.date,
    "message-status": _ctx.messageStatus,
    class: "chat-call",
    "onUpdate:selected": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:selected', $event)))
  }, {
    menu: _withCtx(() => [
      _createVNode(_component_tm_dropdown_item, {
        icon: "check_box",
        label: "Select",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:selected', !_ctx.selected)))
      }),
      (_ctx.callType !== 'missed')
        ? (_openBlock(), _createBlock(_component_tm_dropdown_item, {
            key: 0,
            icon: "info",
            label: "Call details",
            onClick: _ctx.openDetailsModal
          }, null, 8, ["onClick"]))
        : _createCommentVNode("", true),
      _createVNode(_component_tm_dropdown_item, {
        icon: "tmi-duplicate",
        label: "Copy"
      }),
      _createVNode(_component_tm_dropdown_item, {
        icon: "delete",
        label: "Delete"
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_chat_call_type, {
          "call-type": _ctx.callType,
          outcoming: _ctx.outcoming
        }, null, 8, ["call-type", "outcoming"]),
        (_ctx.callType !== 'missed')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, " (" + _toDisplayString(_ctx.duration) + ") ", 1))
          : _createCommentVNode("", true),
        _createVNode(_component_chat_message_info_spacer, {
          outcoming: _ctx.outcoming,
          date: _ctx.date,
          "message-status": _ctx.messageStatus
        }, null, 8, ["outcoming", "date", "message-status"])
      ])
    ]),
    _: 1
  }, 8, ["selected", "outcoming", "date", "message-status"]))
}