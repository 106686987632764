import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4ea509d6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chat-message-image" }
const _hoisted_2 = { class: "chat-message-image__wrapper" }
const _hoisted_3 = { class: "chat-message-image__buttons" }
const _hoisted_4 = { class: "chat-message-image__button" }
const _hoisted_5 = { class: "chat-message-image__button" }
const _hoisted_6 = { class: "chat-message-image__button" }
const _hoisted_7 = { class: "chat-message-image__button" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "d-flex mt-1 align-center" }
const _hoisted_10 = { class: "body-text-semi-bold-14 selected--white-text overflow-hidden lh-20 neutral--text" }
const _hoisted_11 = { class: "ml-2 selected--white-text distinct--text flex-shrink-0 body-text-regular-12 lh-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_ellipsis = _resolveComponent("tm-ellipsis")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_tm_icon, {
            name: "get_app",
            size: "xLarge",
            class: "white--text"
          })
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_tm_icon, {
            name: "tmi-duplicate",
            size: "xLarge",
            class: "white--text"
          })
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_tm_icon, {
            name: "tmi-open-fullscreen",
            size: "xLarge",
            class: "white--text"
          })
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_tm_icon, {
            name: "launch",
            size: "xLarge",
            class: "white--text"
          })
        ])
      ]),
      _createElementVNode("img", { src: _ctx.url }, null, 8, _hoisted_8)
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_tm_ellipsis, { inline: "" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.title) + "." + _toDisplayString(_ctx.extension), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.size), 1),
      _renderSlot(_ctx.$slots, "append", {}, undefined, true)
    ])
  ]))
}