import type { Component, Ref } from 'vue'
import { reactive, ref, watch } from 'vue'
import type { ChatRate } from '@/definitions/shared/chat/types'
import type { WidgetStates, ChattingPopoverStatus, WidgetSignup, WebWidgetSubSection } from '@/definitions/chats/settings/web-widget/types'

type ChatWidgetAppearanceSettings = {
  isGradient: boolean;
  primaryColor: string;
  title: string;
  subtitle: string;
  showTooltip: boolean;
  buttonText: string;
  messageText: string;
}
type UseComposeWidget = {
  chatHidden: any;
  currentState: any;
  showPopover: any;
  popoverStatus: any;
  signup: any;
  rateStatus: any;
  rateMessage: any;
  settingsSections: Ref<WebWidgetSubSection[]>;
  bindChat: (val?: boolean | undefined) => void;
  updateCurrentState: (state: WidgetStates) => void;
  updateShowPopover: (state: boolean) => void;
  updatePopoverStatus: (state: ChattingPopoverStatus) => void;
  updateSignup: (state: WidgetSignup) => void;
  startNewChat: () => void;
  updateRateStatus: (rate: ChatRate) => void;
  updateRateMessage: (message: string) => void;
  widgetAppearanceSettings: ChatWidgetAppearanceSettings;
  widgetSettingsUpdateState: (sections: WebWidgetSubSection[], stateIfEmpty?: WidgetStates) => void;
  alertComponent: Ref<Component | null>;
}

const chatHidden = ref<boolean>(true)
const currentState = ref<any>('welcome')
const showPopover = ref<boolean>(false)
const popoverStatus = ref<any>('end')
const signup = ref<WidgetSignup>({
  name: '',
  email: '',
  message: '',
  phone: '',
})
const rateStatus = ref<ChatRate>('')
const rateMessage = ref<any>('')
const settingsSections = ref<WebWidgetSubSection[]>([])
const alertComponent = ref<Component | null>(null)

const widgetAppearanceSettings = reactive<ChatWidgetAppearanceSettings>({
  isGradient: true,
  primaryColor: 'blue',
  title: 'How can we help? 👋',
  subtitle: 'Select your preferred communication channel',
  showTooltip: false,
  buttonText: '',
  messageText: '👋 Hello, how can we help?',
})

const useWidget = ():UseComposeWidget => {
  const bindChat = (val: boolean | undefined) => {
    if (val !== undefined) {
      chatHidden.value = !val
    } else {
      chatHidden.value = !chatHidden.value
    }
  }
  const updateCurrentState = (state: WidgetStates) => {
    currentState.value = state
  }
  const updateShowPopover = (state: boolean) => {
    showPopover.value = state
  }
  const updatePopoverStatus = (state: ChattingPopoverStatus) => {
    popoverStatus.value = state
  }
  const updateSignup = (value: WidgetSignup) => {
    signup.value = value
  }
  const startNewChat = () => {
    updatePopoverStatus('end')
    updateCurrentState('welcome')
    rateStatus.value = ''
    rateMessage.value = ''
    signup.value = {
      email: '',
      name: '',
      message: '',
      phone: '',
    }
  }
  const updateRateStatus = (rate: ChatRate) => {
    rateStatus.value = rate
  }
  const updateRateMessage = (message: string) => {
    rateMessage.value = message
    updateShowPopover(false)
  }

  const widgetSettingsUpdateState = (sections: WebWidgetSubSection[], stateIfEmpty: WidgetStates = 'welcome') => {
    settingsSections.value = sections
    const setCurrState = (arr: WebWidgetSubSection[]) => {
      const activeItem = sections.find((item) => item.opened)
      if (activeItem) {
        if (activeItem?.widgetState) {
          updateCurrentState(activeItem.widgetState)
        }
        if (activeItem?.hiddenWidget) {
          bindChat(false)
        } else {
          bindChat(true)
        }
      } else {
        updateCurrentState(stateIfEmpty)
        bindChat(true)
      }
    }
    setCurrState(settingsSections.value)
    watch(settingsSections.value, (newVal: WebWidgetSubSection[]) => {
      setCurrState(newVal)
    })
  }

  return {
    chatHidden,
    currentState,
    showPopover,
    popoverStatus,
    signup,
    rateStatus,
    rateMessage,
    bindChat,
    updateCurrentState,
    updateShowPopover,
    updatePopoverStatus,
    updateSignup,
    startNewChat,
    updateRateStatus,
    updateRateMessage,
    widgetAppearanceSettings,
    widgetSettingsUpdateState,
    settingsSections,
    alertComponent,
  }
}

export default useWidget
