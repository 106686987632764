<template>
  <div class="chat-new-divider">
    <span class="body-text-regular-12 error--text mx-1">
      New
    </span>
  </div>
</template>

<style lang="scss" scoped>
.chat-new-divider {
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-top: 16px;
  width: 100%;
  line-height: 16px;

  &::before,
  &::after {
    content: '';
    display: block;
    height: 1px;
    background: $red;
    flex: 1;
  }
}
</style>
