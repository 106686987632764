import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-389ad886"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex justify-between align-center" }
const _hoisted_2 = { class: "body-text-semi-bold-14 lh-20" }
const _hoisted_3 = { class: "body-text-regular-12 lh-16 distinct--text text-right hide-on-hover" }
const _hoisted_4 = { class: "d-flex align-center justify-between" }
const _hoisted_5 = { class: "body-text-semi-bold-12 lh-18" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_button_more = _resolveComponent("tm-button-more")!
  const _component_tm_dropdown_item = _resolveComponent("tm-dropdown-item")!
  const _component_tm_dropdown = _resolveComponent("tm-dropdown")!
  const _component_highlight = _resolveComponent("highlight")!
  const _component_tm_button = _resolveComponent("tm-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["chat-note", {
      'chat-note--dropdown-active': _ctx.dropdownValue,
    }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.author), 1),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.date), 1),
      _createVNode(_component_tm_dropdown, {
        modelValue: _ctx.dropdownValue,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dropdownValue) = $event)),
        class: "show-on-hover"
      }, {
        menu: _withCtx(() => [
          _createVNode(_component_tm_dropdown_item, {
            icon: "edit",
            label: "Edit note",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openModal('note', {
              initValue: _ctx.text,
              modalTitle: 'Edit contact note',
              btnText: 'Save changes',
            })))
          }),
          _createVNode(_component_tm_dropdown_item, {
            icon: "tmi-duplicate",
            label: "Copy text"
          }),
          _createVNode(_component_tm_dropdown_item, {
            icon: "delete",
            label: "Delete",
            onClick: _ctx.openDeleteNoteModal
          }, null, 8, ["onClick"])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_tm_button_more, {
            "icon-size": "xLarge",
            color: "transparent-note",
            size: "small",
            flat: ""
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _createVNode(_component_highlight, {
      class: "body-text-regular-14 lh-20",
      search: _ctx.search ? '857' : '',
      text: _ctx.isLongText && !_ctx.isExpanded ? _ctx.internalText : _ctx.text
    }, null, 8, ["search", "text"]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_tm_button, {
        "text-link": "",
        class: "primary--text",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isExpanded = !_ctx.isExpanded))
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.isExpanded ? 'Show less' : 'Show more'), 1)
        ]),
        _: 1
      })
    ])
  ], 2))
}