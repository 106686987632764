import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a5ed4a2c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chat-message-attachment" }
const _hoisted_2 = { class: "d-flex align-center overflow-hidden" }
const _hoisted_3 = ["title"]
const _hoisted_4 = { class: "selected--white-text truncate flex-shrink-1 flex-grow-1 neutral--text blue-on-hover pointer" }
const _hoisted_5 = { class: "lh-16 selected--white-text body-text-regular-12 ml-6px flex-shrink-0 distinct--text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_file = _resolveComponent("tm-file")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tm_file, {
      format: _ctx.extension,
      class: "mr-6px",
      url: _ctx.url,
      "show-preview": !!_ctx.url,
      size: "small",
      outline: ""
    }, null, 8, ["format", "url", "show-preview"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "lh-20 flex-shrink-1 flex-basis-0 flex-grow-1 overflow-hidden body-text-semi-bold-14 neutral--text",
        title: _ctx.title + '.' + _ctx.extension
      }, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.title) + "." + _toDisplayString(_ctx.extension), 1)
      ], 8, _hoisted_3),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.size), 1),
      _renderSlot(_ctx.$slots, "append", {}, undefined, true)
    ])
  ]))
}